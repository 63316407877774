var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"group-update page-layout"},[_c('PageHeader',{attrs:{"title":"Informations du groupe","has-back":true,"has-actions":true},on:{"back":_vm.onGlobalCancel}},[_c('md-button',{staticClass:"md-raised",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },on:{"click":_vm.openConfirmTrashModal}},[_vm._v(" Supprimer ce groupe "),_c('md-icon',[_vm._v("delete_forever")])],1),_c('md-button',{staticClass:"md-raised",class:{
        'md-primary': !_vm.isJEP,
        'md-alternate': _vm.isJEP,
      },attrs:{"disabled":!(_vm.isInfosModified || _vm.isCompaniesModified)},on:{"click":_vm.onGlobalSave}},[_vm._v(" Sauvegarder ")])],1),(_vm.isLoading)?_c('LoadingCard'):_vm._e(),(!_vm.isLoading && _vm.error)?_c('md-card',{staticClass:"md-layout-item md-size-100"},[_c('md-card-content',[_vm._v(" Groupe non trouvable "),_c('md-empty-state')],1)],1):_c('md-card',{staticClass:"md-layout-item md-size-100"},[_c('md-card-content',[_c('h3',[_vm._v("Informations")]),_c('div',{staticClass:"meep-input"},[_c('md-field',{class:{ 'md-invalid': _vm.errors.has('name') }},[_c('label',[_vm._v("Nom *")]),_c('md-input',{directives:[{name:"validate",rawName:"v-validate",value:('required'),expression:"'required'"}],attrs:{"name":"name","type":"text"},model:{value:(_vm.newInfos.name),callback:function ($$v) {_vm.$set(_vm.newInfos, "name", $$v)},expression:"newInfos.name"}}),_c('span',{directives:[{name:"show",rawName:"v-show",value:(_vm.errors.has('name')),expression:"errors.has('name')"}],staticClass:"md-error"},[_vm._v(" "+_vm._s(_vm.errors.first("name"))+" ")])],1)],1)]),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised",class:{
          'md-accent': _vm.isInfosModified,
          'meep-disabled': !_vm.isInfosModified,
        },attrs:{"disabled":!_vm.isInfosModified},nativeOn:{"click":function($event){return _vm.onInfosCancel.apply(null, arguments)}}},[_vm._v(" Annuler ")]),_c('md-button',{staticClass:"md-raised",class:{
          'md-primary': _vm.isInfosModified,
          'meep-disabled': !_vm.isInfosModified,
        },attrs:{"disabled":!_vm.isInfosModified},nativeOn:{"click":function($event){return _vm.onInfosSave.apply(null, arguments)}}},[_vm._v(" Sauvegarder ")])],1)],1),_c('md-card',{staticClass:"md-layout-item md-size-100 md-small-size-100"},[_c('md-card-header',[_c('div',{staticClass:"md-title"},[_vm._v(" Entreprises liées "),_c('md-button',{staticClass:"md-icon-button",nativeOn:{"click":function($event){return _vm.openSelectCompaniesModal.apply(null, arguments)}}},[_c('md-icon',[_vm._v("add_box")]),_c('md-tooltip',[_vm._v("Ajouter des entreprises")])],1)],1)]),_c('md-card-content',[(_vm.newCompanies && _vm.newCompanies.length)?_c('md-table',[_c('md-table-row',[_c('md-table-head',{staticStyle:{"width":"25%"}},[_vm._v("Nom")]),_c('md-table-head',[_vm._v("Numéro de SIREN")])],1),_vm._l((_vm.newCompanies),function(company,index){return _c('md-table-row',{key:index},[_c('md-table-cell',[_vm._v(_vm._s(company.name))]),_c('md-table-cell',[_vm._v(_vm._s(company.siren))]),_c('md-table-cell',[_c('md-button',{staticClass:"md-icon-button",on:{"click":function($event){return _vm.newCompanies.splice(index, 1)}}},[_c('md-icon',[_vm._v("clear")])],1),_c('md-tooltip',[_vm._v("Retirer cette entreprise du groupe")])],1)],1)})],2):_c('md-empty-state',{attrs:{"md-description":"Ce groupe ne contient aucune entreprise"}})],1),_c('md-card-actions',[_c('md-button',{staticClass:"md-raised",class:{
          'md-accent': _vm.isCompaniesModified,
          'meep-disabled': !_vm.isCompaniesModified,
        },attrs:{"disabled":!_vm.isCompaniesModified},nativeOn:{"click":function($event){return _vm.onCompaniesCancel.apply(null, arguments)}}},[_vm._v(" Annuler ")]),_c('md-button',{staticClass:"md-raised",class:{
          'md-primary': _vm.isCompaniesModified,
          'meep-disabled': !_vm.isCompaniesModified,
        },attrs:{"disabled":!_vm.isCompaniesModified},nativeOn:{"click":function($event){return _vm.onCompaniesSave.apply(null, arguments)}}},[_vm._v(" Sauvegarder ")])],1)],1),(_vm.isSelectCompaniesModalOpen)?_c('select-items-modal',{attrs:{"items-list":_vm.companiesCatalog,"selected-list":_vm.newCompanies,"text":_vm.selectCompaniesModalText,"is-debug":_vm.isDebug},on:{"close":_vm.closeSelectCompaniesModal,"saved":_vm.onCompaniesSelected}}):_vm._e(),(_vm.isConfirmGlobalCancelModalOpen)?_c('confirm-action-modal',{attrs:{"text":_vm.confirmGlobalCancelModalText,"object-to-act-upon":{}},on:{"close":_vm.closeConfirmGlobalCancelModal,"confirm":_vm.goBack}}):_vm._e(),(_vm.isConfirmTrashModalOpen)?_c('confirm-action-modal',{attrs:{"text":_vm.confirmTrashModalText,"object-to-act-upon":_vm.groupe},on:{"close":_vm.closeConfirmTrashModal,"confirm":_vm.onTrash}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }