<template>
  <div class="group-update page-layout">
    <PageHeader
      title="Informations du groupe"
      :has-back="true"
      :has-actions="true"
      @back="onGlobalCancel"
    >
      <md-button
        class="md-raised"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="openConfirmTrashModal"
      >
        Supprimer ce groupe
        <md-icon>delete_forever</md-icon>
      </md-button>

      <md-button
        :disabled="!(isInfosModified || isCompaniesModified)"
        class="md-raised"
        :class="{
          'md-primary': !isJEP,
          'md-alternate': isJEP,
        }"
        @click="onGlobalSave"
      >
        Sauvegarder
      </md-button>
    </PageHeader>

    <LoadingCard v-if="isLoading" />

    <md-card v-if="!isLoading && error" class="md-layout-item md-size-100">
      <md-card-content>
        Groupe non trouvable
        <md-empty-state />
      </md-card-content>
    </md-card>

    <!-- ## Les informations de l'utilisateur ## -->
    <md-card v-else class="md-layout-item md-size-100">
      <md-card-content>
        <h3>Informations</h3>
        <!-- Nom -->
        <div class="meep-input">
          <md-field :class="{ 'md-invalid': errors.has('name') }">
            <label>Nom *</label>
            <md-input
              v-model="newInfos.name"
              v-validate="'required'"
              name="name"
              type="text"
            ></md-input>
            <span v-show="errors.has('name')" class="md-error">
              {{ errors.first("name") }}
            </span>
          </md-field>
        </div>
      </md-card-content>

      <md-card-actions>
        <!-- Le bouton annuler -->
        <md-button
          class="md-raised"
          :class="{
            'md-accent': isInfosModified,
            'meep-disabled': !isInfosModified,
          }"
          :disabled="!isInfosModified"
          @click.native="onInfosCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-raised"
          :class="{
            'md-primary': isInfosModified,
            'meep-disabled': !isInfosModified,
          }"
          :disabled="!isInfosModified"
          @click.native="onInfosSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <!-- ## ENTREPRISES LIEES ##-->
    <md-card class="md-layout-item md-size-100 md-small-size-100">
      <!-- Le titre -->
      <md-card-header>
        <div class="md-title">
          Entreprises liées
          <md-button
            class="md-icon-button"
            @click.native="openSelectCompaniesModal"
          >
            <md-icon>add_box</md-icon>
            <md-tooltip>Ajouter des entreprises</md-tooltip>
          </md-button>
        </div>
      </md-card-header>

      <md-card-content>
        <!-- La liste des entreprises liées -->
        <md-table v-if="newCompanies && newCompanies.length">
          <!-- Le table head -->
          <md-table-row>
            <md-table-head style="width: 25%;">Nom</md-table-head>

            <md-table-head>Numéro de SIREN</md-table-head>
          </md-table-row>

          <!-- Les lignes de la table -->
          <md-table-row v-for="(company, index) in newCompanies" :key="index">
            <!-- Nom -->
            <md-table-cell>{{ company.name }}</md-table-cell>

            <md-table-cell>{{ company.siren }}</md-table-cell>

            <!-- Retirer de la liste -->
            <md-table-cell>
              <md-button
                class="md-icon-button"
                @click="newCompanies.splice(index, 1)"
              >
                <md-icon>clear</md-icon>
              </md-button>
              <md-tooltip>Retirer cette entreprise du groupe</md-tooltip>
            </md-table-cell>
          </md-table-row>
        </md-table>

        <!-- À afficher lorqu'il n'y a personne -->
        <md-empty-state
          v-else
          md-description="Ce groupe ne contient aucune entreprise"
        ></md-empty-state>
      </md-card-content>

      <md-card-actions>
        <!-- Le bouton annuler -->
        <md-button
          class="md-raised"
          :class="{
            'md-accent': isCompaniesModified,
            'meep-disabled': !isCompaniesModified,
          }"
          :disabled="!isCompaniesModified"
          @click.native="onCompaniesCancel"
        >
          Annuler
        </md-button>

        <!-- Le bouton sauvegarder -->
        <md-button
          class="md-raised"
          :class="{
            'md-primary': isCompaniesModified,
            'meep-disabled': !isCompaniesModified,
          }"
          :disabled="!isCompaniesModified"
          @click.native="onCompaniesSave"
        >
          Sauvegarder
        </md-button>
      </md-card-actions>
    </md-card>

    <select-items-modal
      v-if="isSelectCompaniesModalOpen"
      :items-list="companiesCatalog"
      :selected-list="newCompanies"
      :text="selectCompaniesModalText"
      :is-debug="isDebug"
      @close="closeSelectCompaniesModal"
      @saved="onCompaniesSelected"
    />

    <confirm-action-modal
      v-if="isConfirmGlobalCancelModalOpen"
      :text="confirmGlobalCancelModalText"
      :object-to-act-upon="{}"
      @close="closeConfirmGlobalCancelModal"
      @confirm="goBack"
    />

    <confirm-action-modal
      v-if="isConfirmTrashModalOpen"
      :text="confirmTrashModalText"
      :object-to-act-upon="groupe"
      @close="closeConfirmTrashModal"
      @confirm="onTrash"
    />
  </div>
</template>

<script>
/* Modèles */
import groupesModel from "../../model/groupes";
import companiesModel from "../../model/companies";

/* Composants */
import selectItemsModal from "../../components/modal/select-items";
import confirmActionModal from "../../components/modal/confirm-action";
import PageHeader from "@/components/PageHeader";
import LoadingCard from "@/components/LoadingCard";
import { sortByKey } from "@/services/util";

import { mapGetters } from "vuex";

export default {
  name: "UpdateGroup",

  components: {
    PageHeader,
    LoadingCard,
    "select-items-modal": selectItemsModal,
    "confirm-action-modal": confirmActionModal,
  },

  data() {
    return {
      isLoading: false,
      error: false,

      canShowToastedMessages: false,

      /* Informations de l'utilisateur */
      groupe: {},

      /* Tampons pour éditer les informations */
      newInfos: {},
      newCompanies: [],

      /* Les listes qui seront passées dans les selectItemsModals */
      companiesCatalog: [],

      /* Si les modals sont ouverts */
      isSelectCompaniesModalOpen: false,
      isConfirmGlobalCancelModalOpen: false,
      isConfirmTrashModalOpen: false,

      /* Texte des modals */
      selectCompaniesModalText: {
        header: "Selectionner les entreprises",
      },
      confirmGlobalCancelModalText: {
        header: "Des modifications n'ont pas étés sauvegardées",
        body() {
          return "";
        },
        question: "Êtes-vous sûr de vouloir quitter cette page ?",
      },
      confirmTrashModalText: {
        header: "Suppression d'un groupe",
        body(groupe) {
          return (
            "Vous êtes sur le point de supprimer le groupe <b>" +
            groupe.name +
            "</b>, cette action est irréversible."
          );
        },
        question: "Êtes-vous certain de vouloir le faire ?",
      },
    };
  },

  computed: {
    ...mapGetters(["isJEP"]),

    isInfosModified() {
      return this.groupe.name !== this.newInfos.name;
    },

    isCompaniesModified() {
      if (!this.groupe || !this.groupe.companies) {
        return false;
      }

      /* Des noms de variables plus courts */
      let newC = this.newCompanies;
      let oldC = this.groupe.companies;

      /* Si les deux listes n'ont pas la même taille,
       ** elles ont forcément été modifiées */
      if (newC.length !== oldC.length) return true;

      /* Si les listes ont la même taille, les trier pour les comparer */
      const comparator = (a, b) => {
        if (a.name < b.name) return -1;
        if (a.name > b.name) return 1;
        return 0;
      };

      newC.sort(comparator);
      oldC.sort(comparator);

      /* Comparer */
      for (let i = 0; i < newC.length; i++) {
        /* Des entreprises ont été ajoutées/retirées */
        if (newC[i].id !== oldC[i].id) {
          return true;
        }

        if (newC[i].isFailed) {
          return true;
        }
      }

      /* Les deux listes sont bel et bien les mêmes */
      return false;
    },
  },

  watch: {
    $route: "loadData",
  },

  mounted() {
    this.loadData();
  },
  methods: {
    /* ## EVENEMENTS ## */

    goBack() {
      window.history.back();
    },

    onGlobalSave() {
      if (this.isInfosModified) {
        this.onInfosSave();
      }

      if (this.isCompaniesModified) {
        this.onCompaniesSave();
      }
    },
    onGlobalCancel() {
      if (this.isInfosModified || this.isCompaniesModified) {
        this.openConfirmGlobalCancelModal();
      } else {
        this.goBack();
      }
    },

    onInfosSave() {
      this.$validator.validateAll().then((result) => {
        if (!result) {
          this.$toasted.global.AppInfo({
            message: "Vous devez remplir les champs manquants",
          });
          return;
        }

        groupesModel
          .update(this.newInfos)
          .then(() => {
            this.groupe.name = this.newInfos.name;

            this.$toasted.global.AppSucces({
              message: "Le nom du groupe a bien été mis à jour",
            });
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });
          });
      });
    },
    onInfosCancel() {
      this.newInfos = {
        id: this.groupe.id,
        name: this.groupe.name,
      };

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onCompaniesSelected(companies) {
      this.newCompanies = companies;
    },

    onCompaniesSave() {
      let success = true;
      let counter = 0;

      /* Filtrer les entreprise à virer */
      let oldCompanies = this.groupe.companies.filter((company) => {
        return !(
          this.newCompanies.find((element) => {
            return element.id === company.id;
          }) !== undefined
        );
      });

      /* virer les entreprises */
      oldCompanies.forEach((company) => {
        groupesModel.removeCompany(this.groupe.id, company.id).catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
      });

      /* Filtrer les entreprises à ajouter */
      let newCompanies = this.newCompanies.filter((company) => {
        return !(
          this.groupe.companies.find((element) => {
            return element.id === company.id;
          }) !== undefined
        );
      });

      let length = newCompanies.length;

      /* ajouter les entreprises */
      newCompanies.forEach((company) => {
        groupesModel
          .addCompany(this.groupe.id, company.id)
          .then(() => {
            company.isFailed = false;
            counter++;
          })
          .catch((err) => {
            this.$toasted.global.AppError({
              message: err.msg,
            });

            company.isFailed = true;
            counter++;
            success = false;
          });
      });

      /* Wait for the forEach to finish before showing success message */
      const waitForEnd = () => {
        if (counter < length) {
          setTimeout(waitForEnd, 100);
        } else {
          if (success) {
            this.$toasted.global.AppSucces({
              message: "Les entreprises ont bien été mises à jour",
            });
          }

          this.groupe.companies = this.newCompanies.slice();
        }
      };

      setTimeout(waitForEnd(), 100);
    },

    onCompaniesCancel() {
      this.newCompanies = this.groupe.companies.slice();

      if (this.canShowToastedMessages) {
        this.$toasted.global.AppInfo({
          message: "Les modifications ont bien été annulées",
        });
      }
    },

    onTrash() {
      groupesModel
        .remove(this.groupe.id)
        .then(() => {
          this.$router.push("/dashboard/setting/groupes/");
          this.$toasted.global.AppSucces({
            message: "Le groupe '" + this.groupe.name + "' a bien été supprimé",
          });
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },
    /* ## MODALS ##  */

    openSelectCompaniesModal() {
      companiesModel
        .getAll()
        .then((companies) => {
          this.companiesCatalog = sortByKey(companies, "name");
          this.isSelectCompaniesModalOpen = true;
        })
        .catch((err) => {
          this.$toasted.global.AppError({
            message: err.msg,
          });
        });
    },

    closeSelectCompaniesModal() {
      this.isSelectCompaniesModalOpen = false;
    },

    openConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = true;
    },
    closeConfirmGlobalCancelModal() {
      this.isConfirmGlobalCancelModalOpen = false;
    },

    openConfirmTrashModal() {
      this.isConfirmTrashModalOpen = true;
    },
    closeConfirmTrashModal() {
      this.isConfirmTrashModalOpen = false;
    },

    async loadData() {
      try {
        this.isLoading = true;
        this.groupe = await groupesModel.get(this.$route.params.id);
        this.onInfosCancel();
        this.onCompaniesCancel();
        this.error = false;
      } catch (err) {
        this.$toasted.global.AppError({
          message: err.msg,
        });
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss" scoped></style>
